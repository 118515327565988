<template>
  <el-table
    :data="filteredReport19.finalResult"
    border
    class="h-100"
    size="small"
    v-loading="reportsStore.table_loading"
  >
    <el-table-column
      align="center"
      width="180"
      prop="full_name"
      label="To'liq Ismi"
    />

    <el-table-column align="center" width="180" label="Premiyalar summasi">
      <template slot-scope="scope">
        {{ toRoundFloat(scope.row.premie.total) || 0 }}
      </template>
    </el-table-column>

    <el-table-column align="center" width="180" label="Premiyalar soni">
      <template slot-scope="scope">
        {{ toRoundFloat(scope.row.premie.total_count) || 0 }}
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { toRoundFloat } from '@/utils'

export default {
  computed: {
    ...mapState(['reportsStore']),
    ...mapGetters(['filteredReport19'])
  },

  methods: { toRoundFloat },

  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Premiyaga buyruq' }])
    })
  },

  beforeDestroy() {
    this.$store.dispatch(SET_BREADCRUMB, [])
  }
}
</script>
